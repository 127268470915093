import React, { useEffect, useMemo } from "react";
import Carosel from "./carrousel/index";
import Cabecalho_padrao from "components/cabecalho-padrao/index"
import Rodape from "components/rodape/index"
import mapa from "img/sobrenos/mapa.png"
import poligono from "img/poligono.png"
import mercedes from "img/sobrenos/clientes/mercedes.png"
import { useTranslation } from "react-i18next";
import adcos from "img/sobrenos/clientes/adcos.png"
import almar from "img/sobrenos/clientes/almar.png"
import bvlgari from "img/sobrenos/clientes/bvlgari.png"
import combil from "img/sobrenos/clientes/combil.png"
import mentho from "img/sobrenos/clientes/mentho.png"
import oxbo from "img/sobrenos/clientes/oxbo.png"
import vega from "img/sobrenos/clientes/vega.png"
import suzuki from "img/sobrenos/clientes/suzuki.png"
import mont from "img/sobrenos/clientes/mont.png"
import Metodos from "util/helper";
import Img from "../../img/quattror-vv.png"


import "./style.scss"

const Sobre = () => {

    const { t } = useTranslation(["home"])

    const apiMetodos = useMemo(() => new Metodos(), []);

    useEffect(() => {
        apiMetodos.enableDropScroll()
    }, [])

    return (
        <>
            <Cabecalho_padrao imagem={Img} imagemEscura/>
            <div className="quattror-sobrenos-content">
                <h1 className="sobrenos-titulo">{t("sobrenos.titulo")}</h1>
                <img className="poligono" src={poligono} />
                <div className="sobrenos-video">
                    <div className="videoFrame">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/9Nb1NkA_QGs"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
                             encrypted-media; gyroscope; picture-in-picture">
                    </iframe>
                    </div>
                    <div className="sobrenos-quattror">
                        <div className="img-mapa">
                            <img src={mapa} />
                        </div>
                        <div>
                            <p className="primeiro-texto">{t("sobrenos.primeiroTexto")}
                            </p>
                            <p className="segundo-texto">{t("sobrenos.segundoTexto")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="valores-quattror">
                    <div className="valores-missao-visao">
                        <div className="missao">
                            <h1>{t("sobrenos.tituloMissao")}</h1>
                            <h2>{t("sobrenos.tituloMissao")}</h2>
                            <p>{t("sobrenos.textoMissao")}</p>
                        </div>
                        <div className="visao">
                            <h1>{t("sobrenos.tituloVisao")}</h1>
                            <h2>{t("sobrenos.tituloVisao")}</h2>
                            <p>{t("sobrenos.textoVisaoPrimeiroParagrafo")}</p>
                            <p>{t("sobrenos.textoVisaoSegundoParagrafo")}</p>
                            <p>{t("sobrenos.textoVisaoTerceiroParagrafo")}</p>
                        </div>
                    </div>
                    <div className="valores">
                        <h1>{t("sobrenos.tituloValores")}</h1>
                        <h2>{t("sobrenos.tituloValores")}</h2>
                        <p>{t("sobrenos.missaoValoresPrimeiroParagrafo")}</p>
                        <p>{t("sobrenos.missaoValoresSegundoParagrafo")}</p>
                        <p>{t("sobrenos.missaoValoresTerceiroParagrafo")}</p>
                        <p>{t("sobrenos.missaoValoresQuartoParagrafo")}</p>
                    </div>
                </div>
                <div className="carousel">
                    <Carosel />
                </div>
                <div className="clientes" >
                    <h1>{t("sobrenos.clientes")}</h1>
                    <div className="bloco-imagens">
                        <div className="combo-img">
                            <img src={mercedes} />
                            <img src={adcos} />
                            <img src={almar} />
                            <img src={bvlgari} />
                            <img src={combil} />
                            <img src={mentho} />
                            <img src={oxbo} />
                            <img src={suzuki} />
                            <img src={vega} />
                            <img src={mont} />
                        </div>
                    </div>
                </div>
            </div>
            <Rodape />
        </>
    )

}

export default Sobre;