import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import Metodos from 'util/helper';
import Cabecalho_padrao from '../../../components/cabecalho-padrao';

import './style.scss';

const Header = (props) => {
	// const mensagensPt = [
	//     "Eficiência e Tecnologia",
	//     "Em comércio internacional"
	// ]

	// const mensagensEn = [
	//     "Efficiency and Technology",
	//     "in international trade"
	// ]
	// const mensagensEs = [
	//     "Eficiencia y Tecnología",
	//     "en comercio internacional"
	// ]

	const reloadDanger = useRef(
		throttle(() => {
			document.onkeydown = function (event) {
				if (event.keyCode == 116) {
					event.defaultPrevented();
				}
			};
		}, 3000)
	).current;

	//const [linguagem ] = useState(mensagensPt)
	//const [linguagemIngles ] = useState(mensagensEn)
	//const [linguagemEspanhol ] = useState(mensagensEs)

	const { i18n, t } = useTranslation(['home']);
	const [open] = useState(false);
	const [drop, setDrop] = useState(true);
	const [login, setLogin] = useState(false);
	const helper = useMemo(() => new Metodos(), []);

	useEffect(() => {
		helper.blurInicial('imagem');
	}, []);

	//useEffect(() => {
	//    helper.enableDropScroll()
	//    helper.efeitoFrase(".text1", linguagem)
	//    helper.efeitoFrase(".text2", linguagemIngles)
	//    helper.efeitoFrase(".text3", linguagemEspanhol)
	//    reloadDanger();

	//    document.onkeydown = function (event) {
	//        if (event.keyCode == 116) {
	//           event.preventDefault()
	//       }
	//    }
	//}, [])
	//    const alterarLinguagem = (e) => {
	//        i18n.changeLanguage(e.target.value)

	//         if (e.target.value == 'pt') {
	//             document.getElementById("text1").style.display = "block";
	//             document.getElementById("text2").style.display = "none";
	//             document.getElementById("text3").style.display = "none";
	//         }

	//         if (e.target.value == 'en') {
	//             document.getElementById("text2").style.display = "block";
	//             document.getElementById("text1").style.display = "none";
	//             document.getElementById("text3").style.display = "none";
	//         }

	//         if (e.target.value == 'es') {
	//             document.getElementById("text3").style.display = "block";
	//             document.getElementById("text1").style.display = "none";
	//             document.getElementById("text2").style.display = "none";
	//         }
	//     }

	//função de drop scroll'
	const descerTela = () => {
		window.scrollTo(1200, 1200);
	};

	//drop com scroll ao clicar no botão
	window.addEventListener('scroll', () => {
		if (window.scrollY > 80) {
			setDrop(false);
		} else if (window.scrollY < 80) {
			setDrop(true);
		}
	});

	const [usuario, setUsuario] = useState('');
	const [senha, setSenha] = useState('');

	//logica de enter para login
	document.addEventListener('keypress', (e) => {
		if (e.key === 'Enter') {
			document.getElementById('btn').click();
		}
	});
	const enviaFormulario = async () => {
		var nome = document.getElementById('usuario').value;
		var senha = document.getElementById('senhasite').value;

		var usuarioEncoded = btoa(nome);
		var senhasiteEncoded = btoa(senha);

		document.getElementsByName('usuario_encoded')[0].value = usuarioEncoded;
		document.getElementsByName('senhasite_encoded')[0].value =
			senhasiteEncoded;

		document.getElementById('loginForm').submit();

		document.getElementById('usuario').value = null;
		document.getElementById('senhasite').value = null;
	};
	return (
		<div className='cabecalho-content'>
			<img src={props.imagem} id='imagem' />
			<div className='header-content' id='header'>
				{login && (
					<form
						action={process.env.REACT_APP_URL_PORTAL}
						// action='http://10.5.10.92/portal-quattror/index.php'
						id='loginForm'
						method='POST'>
						<input
							type='text'
							name='usuario'
							id='usuario'
							placeholder={t('cabecalhoMain.placeholderUsuario')}
						/>
						<input
							type='password'
							name='senhasite'
							id='senhasite'
							placeholder={t('cabecalhoMain.placeholderSenha')}
						/>
						<input
							type='hidden'
							name='usuario_encoded'
							id='usuario'
							placeholder={t('cabecalhoMain.placeholderUsuario')}
						/>
						<input
							type='hidden'
							name='senhasite_encoded'
							id='senhasite'
							placeholder={t('cabecalhoMain.placeholderSenha')}
						/>
						<input
							type='hidden'
							id='origem'
							name='origem'
							value='site'
						/>
						<input type='hidden' name='e' value='L' />
						<a href=''>{t('cabecalhoMain.esqueceuSenha')}</a>
						<a href=''>{t('cabecalhoMain.primeiroAcesso')}</a>
						<button
							id='btn'
							className='btn'
							onClick={() => enviaFormulario()}>
							{t('cabecalhoMain.btnEntrar')}
						</button>
						{/* <a href={`https://portal.quattror.com.br/portal-quattror/?e=L&usuario=${usuario}&senhasite=${senha}`} id="btn" className="btn" target="_blank">{t("cabecalhoMain.btnEntrar")}</a> */}
					</form>
				)}
				{drop && (
					<div className='drop-button' id='drop-button'>
						<span
							className='drop-down'
							id='dropArrow'
							onClick={() => descerTela()}>
							<i className='fa-solid fa-angle-down fa-3x'></i>
						</span>
					</div>
				)}

				<Cabecalho_padrao corte={false} />
			</div>
			{!open && <div className='borda' id='borda'></div>}
			{/* <div className="container-mensagem" id="mensagem">
                <div className="text1 texto" id="text1"></div>
                <div className="text2 texto" id="text2"></div>
                <div className="text3 texto" id="text3"></div>
            </div> */}
		</div>
	);
};

export default Header;
