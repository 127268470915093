import React from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'
import { useState } from "react";
import { useEffect } from "react";
import LoadingContainer from "./LoadingContainer";

import Marcador from "../../img/contato/Marcador.svg"
import Imagem from "../../img/contato/teste.jpg"

import "./style.css"

const Mapa = (props) => {

    const {google} = props;

    const [mapaCarregado, setMapaCarregado] = useState(false);
    const [animacaoMarcador, setAnimacaoMarcador] = useState(google.maps.Animation.DROP);
    const [center, setCenter] = useState({});
    const [infoWindowVisivel, setinfoWindowVisivel] = useState(false);
    const [marcadorSelecionado, setMarcadorSelecionado] = useState(null);
    
    const zoom = 17;
    const mapStyle = [
        {
            "featureType": "administrative.province",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels",
            "stylers": [
                {
                    "lightness": "-8"
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.neighborhood",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#acacac"
                }
            ]
        },
        {
            "featureType": "administrative.neighborhood",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#484848"
                }
            ]
        },
        {
            "featureType": "administrative.neighborhood",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "all",
            "stylers": [
                {
                    "lightness": "-3"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": "72"
                },
                {
                    "visibility": "on"
                },
                {
                    "color": "#333333"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "labels.text",
            "stylers": [
                {
                    "lightness": "23"
                },
                {
                    "visibility": "off"

                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": "30"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "lightness": "-19"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "lightness": "2"
                },
                {
                    "gamma": "1.21"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "saturation": "15"
                },
                {
                    "hue": "#ff0000"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
                {
                    "lightness": "-43"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "lightness": "22"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "weight": "0.12"
                },
                {
                    "lightness": "-23"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "lightness": "71"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 30
                },
                {
                    "visibility": "on"
                },
                {
                    "color": "#666666"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": 10
                },
                {
                    "lightness": 50
                },
                {
                    "visibility": "on"
                },
                {
                    "color": "#494949"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 40
                },
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "saturation": "5"
                },
                {
                    "visibility": "on"
                },
                {
                    "lightness": "5"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "hue": "#ffff00"
                },
                {
                    "lightness": "-24"
                },
                {
                    "saturation": -97
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "saturation": "-88"
                },
                {
                    "lightness": "-23"
                },
                {
                    "visibility": "on"
                },
                {
                    "color": "#669999"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "lightness": -25
                },
                {
                    "saturation": -100
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text",
            "stylers": [
                {
                    "weight": "0.01"
                },
                {
                    "lightness": "0"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "lightness": "-32"
                },
                {
                    "gamma": "2.99"
                }, 
                {
                    "color": "#ffffff"
                }
            ]
        }
    ]

    useEffect(() => {
        setCenter({lat: -20.33708679987926, lng: -40.28637421095546});
    }, [])

    const mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            travelMode: google.maps.TravelMode.Boat,
            center: center
        })

        setMapaCarregado(true);
    }

    const mouseOverMarcador = (props, marker, e) => {
        if (!infoWindowVisivel) {
            setMarcadorSelecionado(marker);
            setinfoWindowVisivel(true);
            setAnimacaoMarcador('');
        }
    }

    const mouseOutMarcador = (props, marker) => {
        setMarcadorSelecionado(null);
        setinfoWindowVisivel(false);
        setAnimacaoMarcador('');
    }

        return (
            <div>
                <Map
                    google={props.google}
                    zoom={zoom}
                    containerStyle={{ height: '450px', width: '100%', border: 0, position: 'relative' }}
                    avoidTolls={true}
                    initialCenter= {{lat: -20.33708679987926, lng: -40.28637421095546}}
                    mapTypeControl={false}
                    streetViewControl={false}
                    fullscreenControl={false}
                    onReady={(mapProps, map) => mapLoaded(mapProps, map)}
                >
                    <Marker
                        icon={Marcador}
                        title={"Quattror S/A"}
                        onMouseover={mouseOverMarcador}
                        onMouseout={mouseOutMarcador}
                        position={{
                            lat: center.lat,
                            lng: center.lng
                        }}
                        animation={animacaoMarcador}
                    >
                    </Marker>
                    <InfoWindow
                        marker={marcadorSelecionado}
                        visible={infoWindowVisivel}
                    >
                        <div>
                            <p className="TituloInfo" ><strong>Quattror S/A</strong></p>
                            <br/>
                            <p className="textoInfo" style={{fontWeight:"400"}}>Edifício Azurra Tower</p>
                            <p className="textoInfo" >Rua João Pessoa de Mattos, <span style={{fontWeight:"400"}}>N°505</span></p>
                            <p className="textoInfo" > Praia da Costa, Vila Velha, ES - <span style={{fontWeight:"400"}}>CEP: 29.101-115</span></p>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        )
    }
export default GoogleApiWrapper(
    (props) => ({
        apiKey: "AIzaSyCY1xGb2PzJrUyCEgLyRk77M0WEju_byTk",
        language: 'pt',
        LoadingContainer: LoadingContainer
    }
    ))(Mapa)