import React, { useEffect, useMemo } from "react";
import { Styled_h1 } from "customizados/estilizados";
import { useTranslation } from "react-i18next";
import Rodape from "components/rodape/index"
import Img from "img/incentivos-escura.png"
import  Metodos  from "util/helper";
import Cabecalho_padrao from "components/cabecalho-padrao";

import "./style.scss"

const Incentivos = () => {

    const { t } = useTranslation(["home"])

    const helper = useMemo(() => new Metodos(), []);

    useEffect(() => {
        helper.enableDropScroll()
    }, [])

    return (
        <>
           <Cabecalho_padrao imagem={Img} imagemEscura  />
            <div className="incentivo-content">
                <div className="incentivo-top-content">
                    <Styled_h1>
                    {t("incentivos.tituloIncentivos")}
                    </Styled_h1>
                    <p>  {t("incentivos.textoIncentivos")}
                    </p>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.funcapEsTitulo")}</Styled_h1>
                    <p> {t("incentivos.funcapEsTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.funcapEsInfo")} </p>
                            <li>{t("incentivos.funcapEsPrimeiroParagrafo")}</li>
                            <li>{t("incentivos.funcapEsSegundoParagrafo")}</li>
                            <li>{t("incentivos.funcapEsTerceiroParagrafo")}</li>
                            <li>{t("incentivos.funcapEsQuartoParagrafo")}</li>
                        </ul>
                    </div>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.proderoRoTitulo")}</Styled_h1>
                    <p> {t("incentivos.proderoRoTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.proderoRoinfo")} </p>
                            <li>{t("incentivos.proderoRoPrimeiroParagrafo")}</li>
                            <li>{t("incentivos.proderoRoSegundoParagrafo")}</li>
                            <li>{t("incentivos.proderoRoTerceiroParagrafo")}</li>
                            <li>{t("incentivos.proderoRoQuartoParagrafo")}</li>
                        </ul>
                    </div>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.ttdScTitulo")}</Styled_h1>
                    <p> {t("incentivos.ttdScTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.ttdScInfo")} </p>
                            <li>{t("incentivos.ttdScPrimeiroParagrafo")}</li>
                            <li>{t("incentivos.ttdScSegundoParagrafo")}</li>
                            <li>{t("incentivos.ttdScTerceiroParagrafo")}</li>
                            <li>{t("incentivos.ttdScQuartoParagrafo")}</li>
                        </ul>
                    </div>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.investImportacaoEsTitulo")}</Styled_h1>
                    <p> {t("incentivos.investImportacaoEsTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.investInfo")} </p>
                            <li>{t("incentivos.investPrimeiroParagrafo")}</li>
                            <li>{t("incentivos.investSegundoParagrafo")}</li>
                            <li>{t("incentivos.investTerceiroParagrafo")}</li>
                        </ul>
                    </div>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.prodeautoPeTitulo")}</Styled_h1>
                    <p> {t("incentivos.prodeautoPeTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.prodeautoPeInfo")} </p>
                            <li>{t("incentivos.prodeautoPePrimeiroParagrafo")}</li>
                            <li>{t("incentivos.prodeautoPeSegundoParagrafo")}</li>
                            <li>{t("incentivos.prodeautoPeTerceiroParagrafo")}</li>
                            <li>{t("incentivos.prodeautoPeQuartoParagrafo")}</li>
                        </ul>
                    </div>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.peap2PeTitulo")}</Styled_h1>
                    <p> {t("incentivos.peap2PeTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.peap2PeInfo")} </p>
                            <li>{t("incentivos.peap2PePrimeiroParagrafo")}</li>
                            <li>{t("incentivos.peap2PeSegundoParagrafo")}</li>
                            <li>{t("incentivos.peap2PeTerceiroParagrafo")}</li>
                            <li>{t("incentivos.peap2PeQuartoParagrafo")}</li>
                        </ul>
                    </div>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.prodepePeTitulo")}</Styled_h1>
                    <p> {t("incentivos.prodepePeTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.prodepePeInfo")} </p>
                            <li>{t("incentivos.prodepePePrimeiroParagrafo")}</li>
                            <li>{t("incentivos.prodepePeSegundoParagrafo")}</li>
                            <li>{t("incentivos.prodepePeTerceiroParagrafo")}</li>
                            <li>{t("incentivos.prodepePeQuartoParagrafo")}</li>
                        </ul>
                    </div>
                </div>
                <div className="incentivos">
                    <Styled_h1>{t("incentivos.atoCotepeTitulo")}</Styled_h1>
                    <p> {t("incentivos.atoCotepeTexto")}
                    </p>
                    <div>
                        <ul>
                            <p> {t("incentivos.atoCotepeInfo")} </p>
                            <li>{t("incentivos.atoCotepePrimeiroParagrafo")}</li>
                            <li>{t("incentivos.atoCotepeSegundoParagrafo")}</li>
              
                        </ul>
                    </div>
                </div>
            </div>
            <Rodape />
        </>
    )
}

export default Incentivos;