const LoadingContainer = () => {
    return (
        <div className='fallback-spinner' style={{ height: '550px', width: '100%', border: 0, position: 'relative' }}>
            <div className='loading component-loader'>
                <div className='effect-1 effects'></div>
            </div>
        </div>
    )
}

export default LoadingContainer;