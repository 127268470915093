
import axios from "axios";
class Metodos {


    enableDropScroll() {
        window.scrollTo(0, 1)
        const scrollLock = require('scroll-lock');
        scrollLock.enablePageScroll();
    }

    blurInicial = (imagem, efeito) => {
        document.getElementById(imagem).style.filter = efeito;
        setTimeout(() => {
            document.getElementById(imagem).style.transition = "none";
        }, 1000)
    }

    efeitoFrase = (texto, linguagem) => {
        class TextScramble {
            constructor(el) {
                this.el = el
                this.chars = "!<>-_\\/[]{}—=+*^?#________"
                this.update = this.update.bind(this)
            }
            setText(newText) {
                const oldText = this.el.innerText
                const length = Math.max(oldText.length, newText.length)
                const promise = new Promise((resolve) => this.resolve = resolve)
                this.queue = []
                for (let i = 0; i < length; i++) {
                    const from = oldText[i] || ''
                    const to = newText[i] || ''
                    const start = Math.floor(Math.random() * 30)
                    const end = start + Math.floor(Math.random() * 30)
                    this.queue.push({ from, to, start, end });
                }
                cancelAnimationFrame(this.frameRequest)
                this.frame = 0
                this.update()
                return promise
            }
            update() {
                let output = ''
                let complete = 0
                for (let i = 0, n = this.queue.length; i < n; i++) {
                    let { from, to, start, end, char } = this.queue[i]
                    if (this.frame >= end) {
                        complete++
                        output += to
                    } else if (this.frame >= start) {
                        if (!char || Math.random() < 0.28) {
                            char = this.randomChar()
                            this.queue[i].char = char
                        }
                        output += `<span className="dud">${char}</span>`
                    } else {
                        output += from
                    }
                }
                this.el.innerHTML = output;
                if (complete === this.queue.length) {
                    this.resolve()
                } else {
                    this.frameRequest = requestAnimationFrame(this.update)
                    this.frame++
                }
            }
            randomChar() {
                return this.chars[Math.floor(Math.random() * this.chars.length)]
            }
        }

        const el = document.querySelector(texto)
        const fx = new TextScramble(el)
        let counter = 0
        const next = () => {
            fx.setText(linguagem[counter])
                .then(() => {
                    setTimeout(next, 2900)
                })
            counter = (counter + 1) % linguagem.length
        }
        next()
    }


    discord = (msg, component, campo) => {

        let embeds = [
            {
                title: "ERRO - www.quattror.com.br",
                color: 15158332,
                footer: {
                    text: ` ''' ${msg} '''`,
                },
                fields: [
                    {
                        name: component,
                        value: `Campo - ${campo}`
                    },
                ],
            },
        ];

        let data = JSON.stringify({ embeds });

        var config = {
            method: "POST",
            // url:'https://discord.com/api/webhooks/872922312796672010/3oKMt8jZ8A6RAcXKq94ll9ro6VwN2KJIhIvGqreuI64Y_MKfM0yaVMvglDmHlB8xHNws', 
            headers: { "Content-Type": "application/json" },
            data: data,
        };
        axios(config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    }
}

export default Metodos;
