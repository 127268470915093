import React, { useEffect } from "react";
import home_portal from "img/Home-Portal.png"
import video_portal2 from "img/video-home.mp4"
import poligono from "img/poligono.png"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TickerTape } from "react-tradingview-embed";

import './style.scss'

const Corpo_pagina_inicial = () => {

    const { t } = useTranslation(["home"])


    useEffect(() => {
    }, [])

    return (
        <div style={{ background: "#f2f2f2" }}>

            {/* <div className="infoMoney-1">
                <TickerTape widgetProps={{
                    "symbols": [
                        {
                            "description": "BRL/USD",
                            "proName": "FX_IDC:BRLUSD"
                        },
                        {
                            "description": "BRL/EUR",
                            "proName": "FX_IDC:BRLEUR"
                        },
                        {
                            "description": "USD/EUR",
                            "proName": "FX_IDC:EURUSD"
                        },

                    ],
                    "colorTheme": "light",
                    "isTransparent": false,
                    "showSymbolLogo": true,
                    "locale": "br"
                }} />
            </div> */}


            <div className="combo-cards">
                <div className="cards">
                    <Link to="projetos">
                        <div className="img-content1 img"></div>
                        <h1>{t("corpoMain.tituloCardProjeto")}</h1>
                        <h3>{t("corpoMain.textoCardProjeto")}</h3>
                    </Link>
                </div>
                <div className="cards">
                    <Link to="incentivos">
                        <div className="img-content2 img"></div>
                        <h1>{t("corpoMain.tituloCardIncentivo")}</h1>
                        <h3>{t("corpoMain.textoCardIncentivo")}</h3>
                    </Link>
                </div>
                <div className="cards">
                    <Link to="inovacao">
                        <div className="img-content3 img"></div>
                        <h1>{t("corpoMain.tituloCardInovacao")}</h1>
                        <h3>{t("corpoMain.textoCardInovacao")}</h3>
                    </Link>
                </div>
                <div className="cards">
                    <Link to="cambio">
                        <div className="img-content4 img"></div>
                        <h1>{t("corpoMain.tituloCardCambio")}</h1>
                        <h3>{t("corpoMain.textoCardCambio")}</h3>
                    </Link>
                </div>
            </div>
            <div className="content-info">
                <img src={poligono} alt="" />
                <div className="combo-info">
                    <h1>{t("corpoMain.infoPortalPirmeiroParagrafo")}
                    </h1>
                    <h3>
                        {t("corpoMain.infoPortalSegundoParagrafo")}
                    </h3>
                    <Link to="inovacao">
                        {t("corpoMain.btnCliqueConheca")}
                    </Link>
                </div>
                <div className="imagen-content">
                    <img src={home_portal} />
                </div>
            </div>
            <div className="video-content">
                <video autoPlay loop muted>
                    <source src={video_portal2} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default Corpo_pagina_inicial;