import styled from "styled-components";

export const Container = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
font-family: 'Lato', sans-serif;
overflow: hidden;
`
export const Container_center = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
overflow: hidden;
`
export const Styled_h1 = styled.h1`
color: rgba(0, 0, 0, 0.699);
font-size: 1.6rem;
margin-bottom: 30px;
`