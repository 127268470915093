import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import certificado from '../../img/certificado.png';
import location from '../../img/location.png';
import phone from '../../img/phone.png';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import InstagramImg from '../../img/instagram.png';
import LinkedinImg from '../../img/linkedin-png-white.png';
import './style.scss';

const Footer = () => {
    const { t } = useTranslation(['home']);

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [data, setData] = useState();

    const enviarEmail = async (e) => {
        e.preventDefault();

        if (nome == '' || email == '') {
            Swal.fire({
                icon: 'error',
                title: 'Nome e E-mail devem estar preenchidos!'
            });
        } else {
            fetch('https://jsonplaceholder.typicode.com/posts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ nome: nome, email: email })
            })
                .then((response) => {
                    if (response.status == 201) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'E-mail cadastrado com sucesso!',
                            showConfirmButton: false,
                            timer: 2500
                        });
                    }
                })
                .catch((erro) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Problemas técnicos'
                    });
                });
        }
    };

    return (
        <div className='footer'>
            <div className='footer-content-info'>
                <div className='intitucional-combo'>
                    <h1>{t('rodapePadrao.institucionalMain')}</h1>
                    <div>
                        <ul>
                            <li>
                                {' '}
                                <Link to='/sobre'>{t('rodapePadrao.sobreNos')}</Link>{' '}
                            </li>
                            <li>
                                <Link to='/servicos'>{t('rodapePadrao.servicos')}</Link>
                            </li>
                            <li>
                                <Link to='/onde'>{t('rodapePadrao.ondeEstamos')}</Link>
                            </li>
                            <li>
                                <Link to='/contatos'>{t('rodapePadrao.contato')}</Link>
                            </li>
                            <li>
                                <Link to='https://quattror.com.br/vagas' target='_blank' rel='noopener noreferrer'>
                                    {t('rodapePadrao.trabalheConosco')}
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to='/projetos'>{t('rodapePadrao.projetos')}</Link>
                            </li>
                            <li>
                                <Link to='/incentivos'>{t('rodapePadrao.incentivos')}</Link>
                            </li>
                            <li>
                                <Link to='/inovacao'>{t('rodapePadrao.inovacao')}</Link>
                            </li>
                            <li>
                                <Link to='/cambio'>{t('rodapePadrao.cambio')}</Link>
                            </li>
                            <li>
                                <Link to='/social'>{t('rodapePadrao.social')}</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='contato'>
                    <h1>{t('rodapePadrao.contatoMain')}</h1>
                    <div>
                        <img src={location} alt='Localização' />
                        <p>{t('rodapePadrao.endereco')}</p>
                    </div>
                    <div>
                        <img src={phone} alt='telefone' />
                        <p>{t('rodapePadrao.numero')}</p>
                    </div>
                    <div className='links-externos'>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                            <a href='https://www.instagram.com/quattror/' target='_blank'>
                                <img className='instagram' src={InstagramImg} />
                            </a>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <a href='https://www.linkedin.com/company/quattrorcomercial/' target='_blank'>
                                <img className='linkedin' src={LinkedinImg} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='certificado'>
                    <h1>{t('rodapePadrao.certificadoMain')}</h1>
                    <img src={certificado} alt='certificado' />
                </div>
            </div>
            <div className='direitos'>
                <p>{t('rodapePadrao.direitosPrimeiraFrase')} </p>
                <p>
                    <Link to='/politica'>{t('rodapePadrao.direitosSegundaFrase')}</Link> |
                </p>
            </div>
        </div>
    );
};

export default Footer;
