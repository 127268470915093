import React from "react";
import Cabecalho from "./cabecalho";
import Corpo from "./corpo/index";
import Rodape from "components/rodape/index"
import imagem from "img/bg-topo.jpg"

const PaginaInicial = () => {

    return (
        <div style={{ background: "#f2f2f2" }}>
            <Cabecalho  imagem={imagem} />
            <Corpo />
            <Rodape />
        </div>
    )
}

export default PaginaInicial;