import React, { useEffect, useMemo } from "react";
import { Container_center } from "customizados/estilizados"
import { useTranslation } from "react-i18next";
import Rodape from "components/rodape/index"
import poligono from "img/poligono.png"
import Img from "img/projeto-escura.png"
import maquinas from "img/cards/maquinas-equipamentos.jpg"
import cosmeticos from "img/cards/cosmeticos.png"
import confeccao from "img/cards/confeccao-industria-textil.jpg"
import ilimenticia from "img/cards/industria-alimenticia.jpg"
import quimica from "img/cards/industria-quimica.jpg"
import eletronicos from "img/cards/eletronicos.jpg"
import aco from "img/cards/aco.jpg"
import automotivas from "img/cards/partes-pecas.png"
import ciclo from "img/circle-solid.svg"
import Metodos from "util/helper";
import Cabecalho_padrao from "components/cabecalho-padrao";

import "./style.scss"

const Projetos = () => {

    const { t } = useTranslation(["home"])

    const apiMetodos = useMemo(() => new Metodos(), []);

    useEffect(() => {
        apiMetodos.enableDropScroll()
    }, [])

    return (
        <>
            <Cabecalho_padrao imagem={Img} imagemEscura/>
            <Container_center className="projeto-content">
            <img className="poligono" src={poligono} />
                <h1 style={{color:'#383838'}}>{t("projetos.tituloProjeto")}</h1>
                <div className="lista-informacoes">
                    <div className="topico-projetos" > <p style={{ color:'#669999', marginRight: '5px' }}>•</p> {t("projetos.primeiraFraseProjeto")}</div>
                    <div className="topico-projetos" > <p style={{ color:'#669999', marginRight: '5px' }}>•</p> {t("projetos.segundaFraseProjeto")}</div>
                    <div className="topico-projetos" > <p style={{ color:'#669999', marginRight: '5px' }}>•</p> {t("projetos.terceiraFraseProjeto")}</div>
                    <div className="topico-projetos" > <p style={{ color:'#669999', marginRight: '5px' }}>•</p> {t("projetos.quartaFraseProjeto")}</div>
                    <div className="topico-projetos" > <p style={{ color:'#669999', marginRight: '5px' }}>•</p> {t("projetos.quintaFraseProjeto")}</div>
                    <div className="topico-projetos" > <p style={{ color:'#669999', marginRight: '5px' }}>•</p> {t("projetos.sextaFraseProjeto")}</div>
                </div>
                <div className="cards_projeto">
                    <div className="categoria left">
                        <img src={maquinas} alt="" />
                        <h3>{t("projetos.tituloMaquinas")}</h3>
                        <div className="square-left"></div>
                    </div>
                    <div className="categoria rigth">
                        <h3 style={{ textAlign: "end" }}>{t("projetos.tituloCosmeticos")}</h3>
                        <img src={cosmeticos} alt="" />
                        <div className="square-rigth"></div>
                    </div>
                    <div className="categoria left">
                        <img src={confeccao} alt="" />
                        <h3>{t("projetos.tituloConfeccao")}</h3>
                        <div className="square-left"></div>
                    </div>
                    <div className="categoria rigth">
                        <h3>{t("projetos.tituloAlimenticia")}</h3>
                        <img src={ilimenticia} alt="" />
                        <div className="square-rigth"></div>
                    </div>
                    <div className="categoria left">
                        <img src={quimica} alt="" />
                        <h3>{t("projetos.tituloQuimica")}</h3>
                        <div className="square-left"></div>
                    </div>

                    <div className="categoria rigth">
                        <h3>{t("projetos.tituloEletroetronicos")}</h3>
                        <img src={eletronicos} alt="" />
                        <div className="square-rigth"></div>
                    </div>

                    <div className="categoria left">
                        <img src={aco} alt="" />
                        <h3>{t("projetos.tituloAco")}</h3>
                        <div className="square-left"></div>
                    </div>
                    <div className="categoria rigth">
                        <h3>{t("projetos.tituloAutomoticas")}</h3>
                        <img src={automotivas} alt="" />
                        <div className="square-rigth"></div>
                    </div>
                </div>
            </Container_center>
            <Rodape />
        </>
    )
}

export default Projetos;