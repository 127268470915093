import React from 'react';

import './style.scss';

const ImgComp = ({ src, ano, texto_array }) => {
    return (
        <>
            <div className='imagem-sobrenos-carousel' index={ano}>
                <img src={src}></img>
                <div className='sobrenos-carousel-info' style={{ width: '100%' }}>
                    <h1 className='back-h1'>{ano}</h1>
                    <h1>{ano}</h1>
                    {texto_array?.map((texto, index) => {
                        return <p index={index}>{texto}</p>;
                    })}
                </div>
            </div>
        </>
    );
};

export default ImgComp;
