import React from 'react';
import Logo from "img/logo.png";
import ClausulasPdf from '../arquivos/clausulas-pedido-compra-ingles-portugues.pdf';
import Clausulas1Pdf from '../arquivos/clausulas-pedido-compra-ingles-portugues1.pdf';
import CodigoCondutaPdf from '../arquivos/codigo-de-conduta-ingles-portugues.pdf';
import CodigoConduta1Pdf from '../arquivos/codigo-de-conduta-ingles-portugues1.pdf';
import ParecerImportacaoContaOrdem from '../arquivos/PARECER_importacao-por-conta-e-ordem-de-terceiros-2019.pdf';
import ParecerImportacaoEncomenda from '../arquivos/PARECER_importacao-por-encomenda-2019.pdf';
import ImportacaoContaOrdem from '../arquivos/importacao-por-conta-e-ordem.pdf';
import ImportacaoEncomenda from '../arquivos/importacao-por-conta-e-ordem.pdf';
import ResumoApoliceXX from '../arquivos/resumo-apolice-xx.pdf';
import ResumoApolice from '../arquivos/resumo-apolice.pdf';

import './index.scss';

const DocumentosPdf = () => {

    return(
        <>
            <div className='fundo-logo'>
                <img src={Logo}/>
                <h5 style={{ color:'white' }}> - Documentos Operacionais</h5>
            </div>      
            <div className="documentos-download">      
                <a href={ClausulasPdf} target="_blank">Download Pdf - Clausulas pedido</a>
                <a href={Clausulas1Pdf} target="_blank">Download Pdf - Clausulas pedido 1</a>
                <a href={CodigoCondutaPdf} target="_blank">Download Pdf - Código de conduta</a>
                <a href={CodigoConduta1Pdf} target="_blank">Download Pdf - Código de conduta 1</a>
                <a href={ParecerImportacaoContaOrdem} target="_blank">Download Pdf - Parecer importação por conta e ordem de terceiros 2019</a>
                <a href={ParecerImportacaoEncomenda} target="_blank">Download Pdf - Parecer importação por encomenda 2019</a>
                <a href={ImportacaoContaOrdem} target="_blank">Download Pdf - Importação por conta e ordem</a>
                <a href={ImportacaoEncomenda} target="_blank">Download Pdf - Importação por encomenda</a>
                <a href={ResumoApoliceXX} target="_blank">Download Pdf - Resumo Apolice XX</a>
                <a href={ResumoApolice} target="_blank">Download Pdf - Resumo Apolice</a>
            </div>
        </>
    )
}

export default DocumentosPdf

