import { Container_center } from 'customizados/estilizados';
import Rodape from '../../components/rodape/index';
import React, { useEffect, useMemo } from 'react';
import img from '../../img/social/img.png';
import { Styled_h1 } from 'customizados/estilizados';
import Metodos from 'util/helper';
import Poligono from 'img/poligono.png';

import ciclo from 'img/circle-solid.svg';

import Cabecalho_padrao from 'components/cabecalho-padrao';

import './style.scss';
import { useTranslation } from 'react-i18next';

const Esg = () => {
    const apiMetodos = useMemo(() => new Metodos(), []);

    const { t } = useTranslation(['home']);

    useEffect(() => {
        apiMetodos.enableDropScroll();
    }, []);

    return (
        <>
            <div className='social-content'>
                <Cabecalho_padrao imagem={img} imagemEscura positionY={'-160px'} />
                <Container_center>
                    <img className='poligono-social' src={Poligono} alt='' />
                    <div className='social-content-combo' style={{ zIndex: '99' }}>
                        <Styled_h1>{t('social.titulo')}</Styled_h1>
                        <p className='sub-titulo'>{t('social.subTitulo')}</p>
                        <div className='pimeiro-texto'>
                            <p className='texto-principal'>{t('social.textoPrincipalUm')}</p>
                            <p className='texto-principal'>{t('social.textoPrincipalDois')}</p>
                            <p className='texto-principal'>{t('social.textoPrincipalTres')}</p>
                        </div>
                        <div className='social'>
                            <div className='caixa-social'>
                                <h4 className='titulo'>{t('social.ambiental')}</h4>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.ambientalTopicoUm')}
                                </li>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.ambientalTopicoDois')}
                                </li>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.ambientalTopicoTres')}
                                </li>
                            </div>
                        </div>
                        <div className='social'>
                            <div className='caixa-social'>
                                <h4 className='titulo'>{t('social.social')}</h4>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.socialTopicoUm')}
                                </li>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.socialTopicoDois')}
                                </li>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.socialTopicoTres')}
                                </li>
                            </div>
                        </div>
                        <div className='social'>
                            <div className='caixa-social'>
                                <h4 className='titulo'>{t('social.governanca')}</h4>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.governancaTopicoUm')}
                                </li>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.governancaTopicoDois')}
                                </li>
                                <li className='texto'>
                                    <img src={ciclo} />
                                    {t('social.governancaTopicoTres')}
                                </li>
                            </div>
                        </div>
                    </div>
                </Container_center>
            </div>
            <Rodape />
        </>
    );
};

export default Esg;
